import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of, tap } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import { readQueryParameters } from '../resources/shared-functions';
import { EnterpriseService } from './enterprise.service';
import { CompanyService } from './company.service';
import { User } from '../model/user.interface';
import { SetupService } from './setup.service';
import { COMPANY_ROLES } from '../resources/roles';
import { BuildDetailsService } from './build-details.service';
@Injectable({
  providedIn: 'root',
})
export class InitializeService {

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private enterpriseService: EnterpriseService,
    private companyService: CompanyService,
    private setupService: SetupService,
    private buildDetailsService: BuildDetailsService
  ) { }

  initialize(): Observable<any> {
    this.buildDetailsService.fetchBuildDetails();
    this.translate.setDefaultLang('en');

    if (!localStorage.getItem('token')) {
      return this.translate.use('en');
    } else {
      return this.userService
        .getCurrentUser()
        .pipe(
          tap((user: User) => {
            sessionStorage.removeItem('user');
            const userData = {
              guid: user.guid,
              companyName: user.company?.name,
              enterpriseName: user.enterprise?.name,
            };
            sessionStorage.setItem('user', JSON.stringify(userData));
          }),
          switchMap((user: User) => {
            if (COMPANY_ROLES.includes(user.role)) {
              this.setupService.companyId = user.company.id;
              return this.companyService.getCompanyInfo();
            }

            this.enterpriseService.enterpriseId = user.enterprise.id;
            const companyId = +readQueryParameters('companyId');

            if (companyId) {
              this.setupService.companyId = companyId;

              return forkJoin([
                this.enterpriseService.getEnterpriseInfo(),
                this.companyService.getCompanyInfo()
              ]);
            }

            return this.enterpriseService.getEnterpriseInfo();
          }),
          tap(() => {
            const companyId = +readQueryParameters('companyId');

            const logo = COMPANY_ROLES.includes(this.userService.role) || !!companyId
              ? this.setupService.currentCompany.logo
              : this.enterpriseService.currentEnterprise.logo;

            this.setupService.setLogo = logo;

            const showMarketplaceBanner = !!(COMPANY_ROLES.includes(this.userService.role) || !!companyId);
            this.setupService.setShowMarketplaceBanner = showMarketplaceBanner;
          }),
          catchError(() => {
            this.translate.use('en');
            return of(null);
          })
        );
    }
  }
}
