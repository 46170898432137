import { Userpilot } from 'userpilot';

export function UserpilotGuard(): boolean {
  const user = JSON.parse(sessionStorage.getItem('user'));
  if (user) {
    Userpilot.identify(
      user.guid, {
      companyName: user.companyName,
      enterpriseName: user.enterpriseName,
    });
    Userpilot.reload();
  }
  return true;
}
