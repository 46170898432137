import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export function AuthGuard(): boolean {
  const router = inject(Router);
  const authService = inject(AuthenticationService);
  if (authService.isLoggedIn) {
    return true;
  }

  router.navigate(['/login']);
  return false;
}
